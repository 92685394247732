const APIS: any = {
    USERS: {
        LOGIN: '/users/login/',
        LOGOUT: '/users/logout/',
        GET_LOGGED_USER: '/users/me/',
        UPDATE_LOGGED_USER: '/users/update/my/details/',
        GET_ALL_USERS: '/users/all/',
        GET_NEW_ACCESS_TOKEN: '/users/token-refresh',
        INVITE_NEW: '/users/userinvite/',
        EDIT_INVITE: '/users/update/',
        REINVITE: '/users/reinvite/',
        DELETE_INVITE: '/users/',
        SET_NEW_PASSWORD: '/users/set-new-password/',
        CHANGE_PASSWORD: '/users/change-password/',
        FORGOT_PASSWORD: '/users/forgot-password/',
        ACCEPT_AGREEMENT: '/users/accept-agreement/',
        UPDATE_IMAGE: '/users/updateimage/',
        VALIDATE_TOKEN: '/users/validatetoken/',
    },
    SETTINGS: {
        GET_ALL_SETTINGS: '/users/settings/details/',
        USER_SETTINGS: '/users/settings/',
    },
    MAPS: {
        GET_DROPDOWN: '/maps/dropdown',
        GET_GEO_DROPDOWN: '/maps/geodropdown',
        GET_MAPS: '/maps/getMapData',
        GET_CIRCLE: '/maps/getcircle',
        GET_CIF_DATA: '/maps/cif',
        GET_EXPLORE_NOW: '/maps/explorenowv2',
        GET_CORE_SOLUTIONS: '/maps/coresolutionfilter',
        GET_MY_PRESENCE: '/maps/getmypresence',
        GET_CURRENCIES: '/maps/currencydropdown',
        GET_POI_MAP_COLOR: '/maps/getmapcolor',
        GET_TOP_DISTRICTS: '/maps/gettopdata',
        GET_BRANDS: '/maps/viewbrands',
    },
    STORIES: {
        GET_ALL_STORIES: '/story/viewstory',
        GET_FEATURED_STORIES: '/story/featuredstory',
    },
    CIF: {
        GET_INOUTFLOW_DATA: '/cif/inflowoutflow',
        GET_EH_GROWTH_DATA: '/cif/ehgrowth',
        GET_METRIC_BREAKDOWN: '/cif/metricbreakdown',
        GET_GEO_SPECIFIC_DATA: '/cif/headingtable',
        GET_CORE_SOLUTIONS_DATA: '/cif/bubblegraph',
        SEND_EMAIL: '/cif/request-email',
        GET_POI_CATEGORIES: '/cif/getcategory/',
        GET_BASIC_CIF: '/cif/getbasiccif/',
        GET_CIF_CTV: '/cif/getCifCtvData/',
        GET_CIF_POI: '/cif/getCifPoiDataByCategory/',
        GET_POI_CAT_DETAILS: '/cif/poicategory/',
        GET_POI_BY_YEAR: '/api/getPoiByTimePeriod/',
    },
    ANALYTICS: {
        USERMETRICS: "/analytics/usermetrics"
    },
    DASHBOARD: {
        GET_BAR_GRAPH: '/api/getBarGraph/',
        GET_BREAKDOWN_TABLE_DATA:'/cif/gettabledata/',
        GET_TREE_MAP: '/api/getTreeMap/',
        GET_TREE_MAP_FOR_SELECTED_CATEGORY: '/api/getDeepDiveTreeMap/',
        GET_HIGHLIGHTS_FOR_ALL_CATEGORIES: '/api/getInsightsHighlights/',
        GET_KPI_VALUES:'/cif/getkpivalues',
        GET_GROWTHINTIME_POIDATA:"/api/getGrowthInTimePoiData/",
        GET_GROWTHINTIME_POIDATA_BY_CATEGORY :'/api/getGrowthInTimePoiDataByCategory/',
        GET_GROWTHINTIME_POIDATA_BY_SUBCATEGORY:'/api/getGrowthInTimePoiDataBySubCategory/',
        GET_GROWTHRATE_ALL_SUBCATEGORIES:'/api/getGrowthRateAllSubCategories/',
        REQUEST_DOWNLOAD_DATA: '/cif/requestdata',
        GET_DEEP_DIVE_HIGHLIGHTS: '/api/getDeepDiveHighlightsView/',
        GET_FILTERED_HIGHLIGHTS:'/api/getFilteredHighlights/'
    },
    HELP:{
        POST_QUERIES:'/users/anyqueries/',
        GET_FAQS:'/users/faqs',
        GET_PRODUCT_TOUR:'/cif/getproducttour',

    }
}

export { APIS };