import React, { Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { RouteConstants } from "./constants";
import { useRecoilValue } from "recoil";
import { authState } from './states';
import { Spinner } from "./ui-elements";

interface ProtectedRouteProps {
    auth: any;
    redirectPath: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ auth, redirectPath }) => {
    if (!auth || !Object.keys(auth).length) {
        return <Navigate to={redirectPath} />;
    }
    return <Outlet />;
}

const Router = () => {
    const auth = useRecoilValue(authState);
    const Login = React.lazy(() => import("./pages/Login"));
    const UpdatePassword = React.lazy(() => import("./pages/UpdatePassword"));
    const AppLayout = React.lazy(() => import('./pages/AppLayout'));
    const Home = React.lazy(() => import("./pages/Home"));
    const SidebarLayout = React.lazy(() => import('./pages/SidebarLayout'));
    const Dashboard = React.lazy(() => import("./pages/Dashboard"));
    const Stories = React.lazy(() => import("./pages/Stories"));
    const Story = React.lazy(() => import("./pages/Story"));
    const AccountLayout = React.lazy(() => import('./pages/AccountLayout'));
    const Profile = React.lazy(() => import("./pages/Profile"));
    const Settings = React.lazy(() => import("./pages/Settings"));
    const Users = React.lazy(() => import("./pages/Users"));
    const SomethingWentWrong = React.lazy(() => import("./pages/SomethingWentWrong"));
    const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));
    const NotFound = React.lazy(() => import("./pages/NotFound"));
    const Help = React.lazy(() => import("./pages/Help"));

    return (
        <Suspense fallback={
            <Spinner spinnerFlag={true} />
        }>
            <Routes>
                <Route path={RouteConstants.login} element={auth && Object.keys(auth).length ? <Navigate to={RouteConstants.root} /> : <Login />} />
                <Route path={RouteConstants.update_password} element={<UpdatePassword />} />
                <Route element={<ProtectedRoute auth={auth} redirectPath={RouteConstants.login} />}>
                    <Route path={RouteConstants.root} element={<AppLayout />}>
                        <Route index element={<Home />} />
                        <Route path="/" element={<SidebarLayout />}>
                            <Route path={RouteConstants.dashboard} element={<Dashboard />} />
                            <Route path={RouteConstants.stories} element={<Stories />} />
                            <Route path={RouteConstants.story_details} element={<Story />} />
                        </Route>
                        <Route path="/" element={<AccountLayout />}>
                            <Route path={RouteConstants.profile} element={<Profile />} />
                            <Route path={RouteConstants.settings} element={<Settings />} />
                            <Route path={RouteConstants.users} element={<Users />} />
                        </Route>
                        <Route path={RouteConstants.something_went_wrong} element={<SomethingWentWrong />} />
                        <Route path={RouteConstants.error} element={<ErrorPage />} />
                        <Route path={RouteConstants.not_found} element={<NotFound />} />
                        <Route path={RouteConstants.help} element={<Help />} />
                    </Route>
                </Route>
            </Routes>
        </Suspense >
    );
};

export default Router;
