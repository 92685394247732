
// Utilities
import { useFetchWrapper, useHandleError } from '../helpers';
import { APIS } from '../constants';
import { currencies, dropdownList, geoJsonState, poiMapColorState, spinnerLiteState, spinnerState } from '../states';
import { useSetRecoilState } from 'recoil';


const useMapsService = () => {
    const fetchWrapper = useFetchWrapper();
    const { getErrorMsg } = useHandleError()
    const setGeoDropdownList = useSetRecoilState(dropdownList);
    const setSpinner = useSetRecoilState(spinnerState);
    const setCurrencyList = useSetRecoilState(currencies);
    const setGeoJSON = useSetRecoilState(geoJsonState);
    const setPoiMapColor = useSetRecoilState(poiMapColorState);
    const setSpinnerLite = useSetRecoilState(spinnerLiteState);

    const getGeoDropdown = async (payload: any, geo_level: string) => {
        setSpinnerLite(prev => prev + 1);
        try {
            const response = await fetchWrapper.post(APIS.MAPS.GET_GEO_DROPDOWN, payload);
            setGeoDropdownList(prev => ({ ...prev, [geo_level]: response }));
            setSpinnerLite(prev => prev - 1);
        } catch (error) {
            setSpinnerLite(prev => prev - 1);
        }
    }

    const getMap = (geoCode: number, currency: string) => {
        setSpinner(prev => prev + 1);
        return fetchWrapper.get(`${APIS.MAPS.GET_MAPS}?geoId=${geoCode}&currency=${currency}`)
            .then((data: any) => {
                setGeoJSON(data);
                setSpinner(prev => prev - 1);
            }).catch((error) => {
                setSpinner(prev => prev - 1);
            });;
    }

    const getExploreNow = () => {
        return fetchWrapper.get(`${APIS.MAPS.GET_EXPLORE_NOW}?geo-code=1`);
    }

    const getFeaturedStories = (geoCode: number, selCurrency: string) => {
        return fetchWrapper.get(`${APIS.STORIES.GET_FEATURED_STORIES}?geo-code=${geoCode}&currency=${selCurrency}`)
    }

    const getCoreSolutions = (geoCode: number) => {
        return fetchWrapper.get(`${APIS.MAPS.GET_CORE_SOLUTIONS}?geo-code=${geoCode}`);
    }

    const getMyPresence = (geoCode: number) => {
        return fetchWrapper.get(`${APIS.MAPS.GET_MY_PRESENCE}?geoId=${geoCode}`);
    }

    const getCurrencies = () => {
        setSpinner(prev => prev + 1);
        return fetchWrapper.get(APIS.MAPS.GET_CURRENCIES)
            .then(response => {
                setCurrencyList(response);
                setSpinner(prev => prev - 1);
            })
            .catch(error => {
                getErrorMsg(error);
                setSpinner(prev => prev - 1);
            });
    }

    const getMapPoiColor = (payload: any) => {
        return fetchWrapper.post(APIS.MAPS.GET_POI_MAP_COLOR, payload)
            .then(response => {
                setPoiMapColor(response);
                return true;
            })
            .catch(error => {
                getErrorMsg(error);
                return true;
            });
    }

    const getTopDistricts = (payload: any) => {
        setSpinnerLite(prev => prev + 1)
        return fetchWrapper.post(APIS.MAPS.GET_TOP_DISTRICTS, payload);
    }

    const getBrands= (payload: any) => {
        return fetchWrapper.post(APIS.MAPS.GET_BRANDS, payload);
    }

    return {
        getGeoDropdown,
        getMap,
        getExploreNow,
        getFeaturedStories,
        getCoreSolutions,
        getMyPresence,
        getCurrencies,
        getMapPoiColor,
        getTopDistricts,
        getBrands,
    }
}

export { useMapsService };