import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundaryWithNavigate from './helpers/ErrorBoundary';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	// <RecoilRoot>
	// 	<App />
	// </RecoilRoot>
	<BrowserRouter>  {/* Ensures that useNavigate can be used */}
		<RecoilRoot>
			{/* <ErrorBoundaryWithNavigate> */}
				{/* <React.StrictMode> */}
					<App />
				{/* </React.StrictMode> */}
			{/* </ErrorBoundaryWithNavigate> */}
		</RecoilRoot>
	</BrowserRouter>
);

