import './App.css';
import { Spinner, Toast } from './ui-elements';
import Router from './Router';

function App() {
	return (
		<div className="h-100">
			<Spinner />
			<Toast />
			{/* <BrowserRouter> */}
				<Router />
			{/* </BrowserRouter> */}
		</div>
	);
}

export default App;
