import { atom, selector } from "recoil";
import { geoStateDefaultSelector } from "../helpers";

export interface geoLevelProps {
    country: string;
    state?: string | null;
    district?: string | null;
    sub_district?: string | null;
}

export interface dropdownProps {
    geoId: number;
    geoName: string;
    coverage?: string | null;
    count?: string | null;
}

export interface geoDataProps {
    dropDownData: dropdownProps[] | null;
    totalCount?: string | null;
    totalDistrictCoverage?: string | null;
}

export interface dropdownListProps {
    countries: geoDataProps,
    states: geoDataProps | null,
    districts: geoDataProps | null,
    sub_districts: geoDataProps | null
}

export const geoState = atom({
    key: 'geoState',
    default: geoStateDefaultSelector,
});

export const selectedGeoNameState = atom({
    key: 'selectedGeoName',
    default: 'India' as String,
});

export const dropdownList = atom({
    key: 'dropdownList',
    default: {
        countries: { dropDownData: [{ geoId: 1, geoName: "India" }] },
        states: null,
        districts: null,
        sub_districts: null,
    } as dropdownListProps,
});