import { selector } from "recoil";
import { geoLevelProps } from "../states"

export const geoStateDefaultSelector = selector({
    key: 'geoStateDefaultSelector',
    get: () => {
        const params: any = { country: '1' };
        if(!window.location.search) return params;
        let searchParams = window.location.search.slice(1);
        const geoKeys = ['country', 'state', 'district', 'sub_district'];
        searchParams?.toString().split('&').forEach((param) => {
            let [key, value]: any = param.split('=');
            if (geoKeys.includes(key) && value) {
                params[key] = value.toString();
            }
        });
        return params;
    },
});

const createImage = (url: any) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

const getRadianAngle = (degreeValue: any) => {
    return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
const rotateSize = (width: any, height: any, rotation: any) => {
    const rotRad = getRadianAngle(rotation)

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export const getCroppedImg = async (
    imageSrc: any,
    pixelCrop: { width: number; height: number; x: number; y: number; },
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) => {
    const image: any = await createImage(imageSrc);
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    const croppedCanvas = document.createElement('canvas')

    const croppedCtx = croppedCanvas.getContext('2d')

    if (!croppedCtx) {
        return null
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width
    croppedCanvas.height = pixelCrop.height

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    )

    // As Base64 string
    // return croppedCanvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        croppedCanvas.toBlob((file) => {
            if (file) {
                return resolve(URL.createObjectURL(file));
            }
        }, 'image/jpeg')
    })
}

export const initialGenerator = (value: string) => {
    if (value) {
        let initial = value.split(' ');
        if (initial[1]) {
            return initial?.[0][0]?.toUpperCase() + initial?.[1][0]?.toUpperCase();
        } else {
            return initial?.[0][0]?.toUpperCase();
        }
    } else {
        return '';
    }
}

const hRange = [0, 360];
// const sRange = [0, 100];
// const lRange = [0, 100];
const sRange = [0, 80];
const lRange = [0, 100];

const getHashOfString = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

const normalizeHash = (hash: number, min: number, max: number) => {
    return Math.floor((hash % (max - min)) + min);
};

const HSLtoString = (hsl: [number, number, number]) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const generateHSL = (name: string) => {
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, hRange[0], hRange[1]);
    const s = normalizeHash(hash, sRange[0], sRange[1]);
    const l = normalizeHash(hash, lRange[0], lRange[1]);
    const hsl = HSLtoString([h, s, l]);
    return hsl;
};

export const getSortedTable = (data: any, item: any, order: string) => {
    let sortedTable = data.slice().sort((a: any, b: any) => {
        const aValue = item.KEY === 'state' ? a['geo_hierarchy']?.[1]?.geo_name : a[item.KEY];
        const bValue = item.KEY === 'state' ? b['geo_hierarchy']?.[1]?.geo_name : b[item.KEY];
        if (!aValue && !bValue) {
            return 0;
        } else if (!aValue) {
            return 1;
        } else if (!bValue) {
            return -1;
        } else if (typeof aValue === 'string' && typeof bValue === 'string') {
            return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            return order === 'asc' ? aValue - bValue : bValue - aValue;
        } else {
            return 0;
        }
    });
    return sortedTable;
}

export const getNumberWithZero = (value: null | number | string) => {
    if (value !== undefined && value !== null) {
        const numericValue = typeof value === 'string' ? parseInt(value, 10) : value;
        if (numericValue < 10) {
            return '0' + numericValue.toString();
        }
        return numericValue.toString();
    }
    return '--';
};

export const getSelectedGeoName = (list: any, geoId: any, geo_level_name: string) => {
    if (!list) return 'Select ' + formatGeoLevelString(geo_level_name).replace(/\s+/g, '');

    const foundItem = list.find((item: any) => item.geoId === Number(geoId));
    if (foundItem) return foundItem.geoName;
    return 'Select ' + formatGeoLevelString(geo_level_name).replace(/\s+/g, '');
}

export const getPoiIconColor = (cifType: string, selCats: number[], catId: number) => {
    if (cifType === 'CTV') {
        return 'bg-poi color-poi';
    } else if (cifType === 'PoI' && selCats.includes(catId)) {
        return 'bg-poi-highlight text-white';
    } else {
        return 'bg-white color-poi';
    }
}

export interface GeoBase {
    geo_id: number;
    geo_value: string;
    code: string
}

export interface State extends GeoBase { }

export interface District extends GeoBase {
    parent: {
        geo_id: number;
        geo_value: string;
        code: string;
    };
}

export interface SubDistrict extends GeoBase {
    parent: {
        geo_id: number;
        geo_value: string;
        code: string;
    };
    grand_parent: {
        geo_id: number;
        geo_value: string;
        code: string;
    };
}


export const transformData = (data: any[]): { states: State[]; districts: District[]; sub_districts: SubDistrict[] } => {
    const result = {
        states: [] as State[],
        districts: [] as District[],
        sub_districts: [] as SubDistrict[],
    };

    function traverse(node: any, parent: any = null, grandParent: any = null) {
        if (!node) return;

        // Process state
        if (!parent && !grandParent) {
            result.states.push({ geo_id: node.geo_id, geo_value: node.geo_value, code: node.code });
        }

        // Process district
        if (parent && !grandParent) {
            result.districts.push({
                geo_id: node.geo_id,
                geo_value: node.geo_value,
                code: node.code,
                parent: { geo_id: parent.geo_id, geo_value: parent.geo_value, code: parent.code },
            });
        }

        // Process sub_district
        if (grandParent) {
            result.sub_districts.push({
                geo_id: node.geo_id,
                geo_value: node.geo_value,
                code: node.code,
                parent: { geo_id: parent.geo_id, geo_value: parent.geo_value, code: parent.code },
                grand_parent: { geo_id: grandParent.geo_id, geo_value: grandParent.geo_value, code: grandParent.code },
            });
        }

        // Recursively process children
        if (node.children && node.children.length > 0) {
            node.children.forEach((child: any) => traverse(child, node, parent));
        }
    }

    // Start traversal
    data.forEach((item) => traverse(item));
    return result;
};

export const formatGeoLevelString = (input: string): string => {
    if (!input) return '';
    return input
        .replace(/_/g, ' - ')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export const joinGeoNames = (item: any) => {
    if (!item.grand_parent && !item.parent) {
        return `${item.geo_value} ${item.code ? `(${item.code})` : ''}`;
    } else if (!item.grand_parent && item.parent) {
        return `${item.geo_value} - ${item.parent.geo_value} ${item.parent.code ? `(${item.parent.code})` : ''}`;
    } else if (item.grand_parent && item.parent) {
        return `${item.geo_value} - ${item.parent.geo_value} - ${item.grand_parent.geo_value} ${item.grand_parent.code ? `(${item.grand_parent.code})` : ''}`;
    } else {
        return '';
    }
}

export const getSearchParamsAsObject = () => {
    const searchParams = new URLSearchParams(window.location.search);
    let paramsObject: Partial<geoLevelProps> = {};

    searchParams.forEach((value, key) => {
        if (key in paramsObject) {
            paramsObject[key as keyof geoLevelProps] = value;
        }
    });

    if (Object.keys(paramsObject).length === 0) {
        paramsObject = { country: '1' };
    }

    return paramsObject as geoLevelProps;
};

export const checkIsBrandSelected = (branchId: any, selBrands: any[]) => {
    if (!selBrands || selBrands.length === 0) return 'color-poi';
    const found = selBrands.flatMap(company => company.branches).find(branch => branch.branchId === branchId);
    if (found) {
        return 'color-orange';
    } else {
        return 'color-poi';
    }
}

export const getHoverLevel = (geoLevel: string, selectedGeo: geoLevelProps) => {
    const { country, state, district, sub_district } = selectedGeo;
    const geo_id = sub_district || district || state || country;

    const shouldStyle =
        (geo_id === country && geoLevel === 'state') ||
        (geo_id === state && geoLevel === 'district') ||
        (geo_id === district);

    return shouldStyle;
}

const distanceFromMapTopToPoint = (boundary: MapBoundary | undefined, zoom: number, lat: number) => {
    if (!boundary) return 0;

    const topLat = boundary.north;
    const bottomLat = boundary.south;

    const latDiff = topLat - bottomLat;

    const latFromTop = topLat - lat;

    const mapHeightInPixels = 256 * Math.pow(2, zoom);
    const distanceFromTopInPixels = (latFromTop / latDiff) * mapHeightInPixels;

    return distanceFromTopInPixels;
};

type MapBoundary = {
    north: number;
    south: number;
    east: number;
    west: number;
};

export const getUpdatedPosition = (lat: number, lng: number, map: any) => {
    if (!map || !map.getBounds()) return { lat, lng };

    const boundary = map.getBounds()?.toJSON() as MapBoundary;

    const distance = distanceFromMapTopToPoint(boundary, map.getZoom() || 1, lat);
    let latOffset = 0;

    if (distance < 8500) {
        const pixelOffset = 50;
        const scale = Math.pow(2, map.getZoom() || 1);
        const projection = map.getProjection();

        if (projection) {
            const point = projection.fromLatLngToPoint(new window.google.maps.LatLng(lat, lng)) as google.maps.Point;

            if (point) {
                const adjustedPoint = new google.maps.Point(point.x, point.y + pixelOffset / scale);

                const adjustedLatLng = projection.fromPointToLatLng(adjustedPoint);
                if (adjustedLatLng) {
                    latOffset = adjustedLatLng.lat() - lat || 0;
                }
            }
        }
    }

    return { lng, lat: lat + latOffset };
};

export const getPoiCount = (cat: any) => {
    if (!cat || !cat.totalPoiCont) return '--';
    if (!cat.selectedCatPoicount || cat.totalPoiCont === cat.selectedCatPoicount) return cat.totalPoiCont;
    else return `${cat.selectedCatPoicount}/${cat.totalPoiCont}`;
}

export const getGeoKeyName = (selectedGeo: geoLevelProps) => {
    const { country, state, district, sub_district } = selectedGeo;
    const geo_id = sub_district || district || state || country;

    const key = (Object.keys(selectedGeo) as Array<keyof typeof selectedGeo>).find(
        key => Number(selectedGeo[key]) === Number(geo_id)
    ) || '';
    if (key === 'state') return 'Nation';
    else return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
};

export const formatLineChartYAxisLabel = (value: any, currency: string) => {
    const formatValue = (formattedValue: any) => {
        return formattedValue.endsWith('.0') ? formattedValue.slice(0, -2) : formattedValue;
    };

    if (currency === 'USD') {
        if (value >= 1e9) {
            return formatValue((value / 1e9).toFixed(1)) + 'B'; // Billion
        } else if (value >= 1e6) {
            return formatValue((value / 1e6).toFixed(1)) + 'M'; // Million
        } else if (value >= 700000) {
            return formatValue((value / 1e6).toFixed(1)) + 'M'; // Convert large K to M
        } else if (value >= 1000) {
            return formatValue((value / 1e3).toFixed(1)) + 'K'; // Keep small K
        }
    } else if (currency === 'INR') {
        if (value >= 1e7) {
            return formatValue((value / 1e7).toFixed(1)) + 'Cr'; // Crore
        } else if (value >= 1e5) {
            return formatValue((value / 1e5).toFixed(1)) + 'L'; // Lakh
        } else if (value >= 70000) {
            return formatValue((value / 1e5).toFixed(1)) + 'L'; // Convert large K to L
        } else if (value >= 1000) {
            return formatValue((value / 1e3).toFixed(0)) + 'K'; // Keep small K
        }
    }

    return value.toString();
};

export const setDocumentTitle = (value?: any) => {
    if (value) {
        document.title = value + ' | EPIC Intelligence';
    } else {
        document.title = 'EPIC Intelligence';
    }
}