
// Utilities
import { useFetchWrapper, useHandleError } from '../helpers';
import { APIS } from '../constants';
import { errorState } from '../states';
import { useSetRecoilState } from 'recoil';


const useDashboardService = () => {
    const fetchWrapper = useFetchWrapper();
    const { getErrorMsg } = useHandleError();
    const setError = useSetRecoilState(errorState);


    const getBarGraphData = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_BAR_GRAPH, payload);
    };

    const getTreeMapData = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_TREE_MAP, payload);
    };

    const getBreakdownTableData = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_BREAKDOWN_TABLE_DATA, payload)
    }
    const getSelectedTreeMapData = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_TREE_MAP_FOR_SELECTED_CATEGORY, payload);
    };

    const getHighlightsData = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_HIGHLIGHTS_FOR_ALL_CATEGORIES, payload);
    };

    const getkpivalues = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_KPI_VALUES, payload);
    }

    const getGrowthInTimePoiData = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_GROWTHINTIME_POIDATA, payload);
    }

    const getSelectedCategoryHighlights = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_DEEP_DIVE_HIGHLIGHTS, payload);
    }

    const getGrowthInTimePoiDataByCategory = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_GROWTHINTIME_POIDATA_BY_CATEGORY, payload);
    }
    const requestDownloadData = async (payload: any) => {
        try {
            const response = await fetchWrapper.post(APIS.DASHBOARD.REQUEST_DOWNLOAD_DATA, payload);
            if (response) {
                setError({ type: 'Success', message: response.detail });
            }
        } catch (error) {
            getErrorMsg(error);
        }

    }

    const getGrowthInTimePoiDataBySubcategory = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_GROWTHINTIME_POIDATA_BY_SUBCATEGORY, payload);
    }
    const getGrowthRateAllSubCategories = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_GROWTHRATE_ALL_SUBCATEGORIES, payload);
    }


    const getFilteredHighlights = (payload: any) => {
        return fetchWrapper.post(APIS.DASHBOARD.GET_FILTERED_HIGHLIGHTS, payload);
    }

    
    return {
        getBarGraphData,
        getBreakdownTableData,
        getTreeMapData,
        getSelectedTreeMapData,
        getHighlightsData,
        getkpivalues,
        getGrowthInTimePoiData,
        getSelectedCategoryHighlights,
        getGrowthInTimePoiDataByCategory,
        getGrowthInTimePoiDataBySubcategory,
        getGrowthRateAllSubCategories,
        requestDownloadData,
        getFilteredHighlights
    }
}

export { useDashboardService };