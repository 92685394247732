import './Spinner.css';
import { spinnerLiteState } from '../../states';
import { useRecoilState } from 'recoil';
// import spinnerGif from '../../utils/images/spinnerGIF.gif'
import spinnerGif2 from '../../utils/images/spinnerGIF2.gif'

export const SpinnerLite = () => {
    const [spinnerLite] = useRecoilState(spinnerLiteState);
    
    return (
        <>
            {/* {spinnerLite > 0 && */}
                <div className="spinner-lite-wrapper">
                    <div className="spinner-lite-overlay d-flex justify-content-center align-items-center">
                        <img src={spinnerGif2} alt="spinner" className="spinner-lite-size" />
                    </div>
                </div>
            {/* } */}
        </>
    )
}
