import React from 'react';
import styles from "./Select.module.css";

export enum SelectSize {
    small,
    medium,
    large,
}

export enum SelectType {
    contained,
    transparent,
}

export enum SelectTheme {
    primary,
    transparent,
    secondary,
}

interface SelectProps<T> {
    options: T[];
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    value: any;
    labelKey: keyof T;
    valueKey: keyof T;
    size: SelectSize;
    disabled?: boolean;
    classname?: string;
    name?: string;
    placeholder?: string;
    theme?: SelectTheme;
}

const getSizeClass = (size: SelectSize) => {
    let className = "";
    switch (size) {
        case SelectSize.small:
            className = `${styles.select_small}`;
            break;
        case SelectSize.medium:
            className = `${styles.select_medium}`;
            break;
        case SelectSize.large:
            className = `${styles.select_large}`;
            break;
    }
    return className;
}

const getColor = (theme: SelectTheme) => {
    let className = "";
    switch (theme) {
        case SelectTheme.primary:
            className = `${styles.select_primary}`;
            break;
        case SelectTheme.secondary:
            className = `${styles.select_secondary}`;
            break;
        case SelectTheme.transparent:
            className = `${styles.select_noborder}`;
            break;
    }
    return className;
}

const getTextColor = (value: any) => {
    return value ? `${styles.select_text_selcted}` : `${styles.select_text_not_selected}`;
}


export const Select = ({
    options,
    onChange,
    value,
    labelKey,
    valueKey,
    disabled,
    classname,
    size,
    name,
    placeholder,
    theme = SelectTheme.transparent
}: SelectProps<any>) => {
    return (
        <select
            className={`${getSizeClass(size)} padding-right-5 ${classname} ${styles.select} ${getTextColor(value)} ${getColor(theme)}`}
            onChange={onChange}
            disabled={disabled}
            name={name}
            value={value || ''}
        >
            {placeholder && <option value="" disabled >{placeholder}</option>}
            {options?.map((option: any, key: number) => (
                <option key={key} value={option[valueKey] as any} className={`${styles.select_text_selcted}`}>
                    {option[labelKey]} {name === 'currency' ? `${option['symbol']}` : ''}
                </option>
            ))}

        </select>
    );
}