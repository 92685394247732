import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { RouteConstants } from "../constants";
import { errorState, userCurrencyState, authState } from "../states";

export const useHandleError = () => {
    const setError = useSetRecoilState(errorState);
    const setUserCurrency = useSetRecoilState(userCurrencyState);
    const setAuth = useSetRecoilState(authState);
    const navigate = useNavigate();

    const getErrorMsg = (error: any) => {
        const detail = error?.response?.data?.detail;
        const tokenErrors = [
            'authentication credentials were not provided.',
            'invalid refresh token',
        ];

        if (detail) {
            if (detail?.toLowerCase() === 'user not found') {
                localStorage.removeItem('user');
                localStorage.removeItem('email_id');
                localStorage.removeItem('currency');
                setAuth({});
                setUserCurrency('');
            } else if (error?.response?.status === 400) {
                setError({ type: 'Error', message: detail });
            } else if (error?.response?.status === 404) {
                navigate(RouteConstants.not_found);
            } else if (
                error?.response?.status === 401 ||
                error?.response?.status === 500
            ) {
                localStorage.removeItem('user');
                localStorage.removeItem('email_id');
                localStorage.removeItem('currency');
                setAuth({});
                setUserCurrency('');
                navigate(RouteConstants.error);
            } else if (!tokenErrors.some((string) => detail.toLowerCase().includes(string))) {
                setError({ type: 'Error', message: detail });
            } else if (detail.includes('Something went wrong')) {
                navigate(RouteConstants.something_went_wrong);
            }
        } else {
            // Default behavior for undefined detail
            navigate(RouteConstants.something_went_wrong);
        }
    };
    return { getErrorMsg };
}