import { MdOutlineGroup, MdOutlineSettings } from 'react-icons/md';
import { FaUserGroup } from 'react-icons/fa6';
import { RouteConstants } from '.';

export interface TableHeaderProps {
    ID: string,
    NAME: string,
    KEYS: { KEY: string, VALUE: string, UOM?: string }[]
}

interface TableProps {
    GEO_INFO_TABLE: TableHeaderProps,
    METRIC_BREAKDOWN_TABLE: TableHeaderProps,
}

export const TABLE_HEADERS: TableProps = {
    GEO_INFO_TABLE: {
        ID: 'geo-info',
        NAME: 'Summary Breakdown',
        KEYS: [
            { KEY: 'geoName', VALUE: 'State' },
            { KEY: 'area', VALUE: 'Area (sq.Km)' },
            { KEY: 'totalEh', VALUE: 'Number of EHs' },
            { KEY: 'tam', VALUE: 'Total Addressable Market', UOM: 'totalEhGtvUOM' },
            { KEY: 'poi', VALUE: 'Points of Interest' }
        ],
    },
    METRIC_BREAKDOWN_TABLE: {
        ID: 'metric-breakdown',
        NAME: 'CTV Breakdown',
        KEYS: [
            { KEY: 'geoName', VALUE: 'State' },
            { KEY: 'entrepreneurialHouseholds', VALUE: 'Entrepreneurial Households' },
            { KEY: 'medianAnnualEhSpend', VALUE: 'Median Annual EH Spend', UOM: 'medianAnnualEhSpendUOM' },
            { KEY: 'medianAnnualEhBorrowing', VALUE: 'Median Annual EH Borrowing', UOM: 'medianAnnualEhBorrowingUOM' },
            { KEY: 'medianAnnualEhIncome', VALUE: 'Median Annual EH Income', UOM: 'medianAnnualEhIncomeUOM' },
            { KEY: 'ehTransactionValue', VALUE: 'EH Transcational Value', UOM: 'ehTransactionValueUOM' }
        ],
    },
}

export interface InviteTableHeader {
    key: string;
    value: string;
}

export const INVITE_TABLE_HEADER: InviteTableHeader[] = [
    {
        key: 'name',
        value: 'Name'
    },
    {
        key: 'role',
        value: 'Role'
    },
    {
        key: 'company',
        value: 'Company'
    },
    {
        key: 'company_type',
        value: 'Type'
    },
    {
        key: 'last_login',
        value: 'Last Active'
    },
    {
        key: 'status',
        value: 'Status'
    },
];

export const REQUEST_INVITE_TABLE_HEADER: InviteTableHeader[] = [
    {
        key: 'name',
        value: 'Name'
    },
    {
        key: 'status',
        value: 'Status'
    },
];

export interface AccountMenuItem {
    key: number;
    text: string;
    route: string;
    Icon: React.ElementType
}

export const ACCOUNT_MENU_ITEMS: AccountMenuItem[] = [
    {
        key: 1,
        text: "My profile",
        route: 'profile',
        Icon: MdOutlineGroup
    },
    {
        key: 2,
        text: "Settings",
        route: 'settings',
        Icon: MdOutlineSettings
    },
    {
        key: 3,
        text: "Users",
        route: 'users',
        Icon: FaUserGroup,
    },
];

export const CURRENCY = [
    { key: 'INR', symbol: '₹' },
    { key: 'USD', symbol: '$' },
];

export const GEO_LEVELS = [
    { key: 'country', name: 'Country', list: 'countries' },
    { key: 'state', name: 'States', list: 'states' },
    { key: 'district', name: 'Districts', list: 'districts' },
    { key: 'sub_district', name: 'Sub-districts', list: 'sub_districts' },
];

export const DATA_CATEGORIES = ['PoI', 'CTV'];

export const GOOGLE_ICON_MAP = new Map([
    [1, 'psychiatry'],
    [2, 'home_health'],
    [3, 'school'],
    [4, 'finance_chip'],
    [5, 'directions_car'],
    [6, 'rss_feed'],
    [7, 'home_work'],
    [8, 'local_mall'],
]);

export const PAGINATION_COUNT_OPTIONS = [
    { key: 25, value: 25 },
    { key: 50, value: 50 },
    { key: 75, value: 75 }
];

export const GEO_VISIBLE_ROUTE = [RouteConstants.root, RouteConstants.dashboard];

export interface basicCifProps {
    address: string;
    averageCtv: string;
    averageCtvActualValue: number;
    eh: string;
    ehActualValue: number;
    geo_id: string;
    geo_name: string;
    region: string;
    totalAddressableMarket: string;
    totalAddressableMarketActualValue: number;
    totalHouseholds: string;
    totalHouseholdsActualValue: number;
    totalPopulation: string;
    totalPopulationActualValue: number;
}

export const CATEGORY_ORDER = ['Healthcare', 'Hyperlocal retail', 'Education', 'Construction', 'Agriculture', 'Mobility', 'Financial Solutions', 'Digital Adoption'];