import React, { useEffect, useState, useRef } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai';
import '../styles/main.css';

interface InfoPanelProps {
    text: string;
    classname?: string;
    translateX?: string;
}
export const InfoPanel: React.FC<InfoPanelProps> = ({ text, classname, translateX }) => {
    const menuRef = useRef<HTMLDivElement | null>(null);
    const popupRef = useRef<HTMLDivElement | null>(null); // Ref for the popup
    const [showPopup, setShowPopup] = useState(false);
    const [popupStyle, setPopupStyle] = useState<{ left: number | string; right: number | string; maxWidth: any; minWidth: string, transform?: string; }>({ left: '', right: '', maxWidth: 192, minWidth: 'fit-content' });

    const handlePopup = () => {
        // Determine the position of the info button
        // const popupWidth = 192;
        const popupWidth = popupRef.current?.getBoundingClientRect().width || 192; 
        const infoButtonPosition = menuRef.current?.getBoundingClientRect();

        if (infoButtonPosition) {
            const hasSpaceOnLeft = infoButtonPosition.left >= popupWidth / 2;
            const hasSpaceOnRight = window.innerWidth - infoButtonPosition.right >= popupWidth / 2;

            if (hasSpaceOnLeft && hasSpaceOnRight) {
                // Enough space on both sides, center the popup
                setPopupStyle({ left: '50%', right: 'auto', maxWidth: popupWidth, minWidth: 'fit-content', transform: 'translateX(-50%)' });
            } else if (hasSpaceOnLeft) {
                // Not enough space on the right, position on the left
                // const rightPosition = -(window.innerWidth - infoButtonPosition.right + window.scrollX - 10);
                const rightPosition = -(window.innerWidth - infoButtonPosition.right + window.scrollX + 10);
                setPopupStyle({ left: 'auto', right: rightPosition, maxWidth: popupWidth, minWidth: 'fit-content', transform: 'translateX(0%)' });
            } else if (hasSpaceOnRight) {
                // Not enough space on the left, position on the right
                const lefttPosition = (infoButtonPosition.left + window.scrollX + 50);
                setPopupStyle({ left: lefttPosition, right: 'auto', maxWidth: popupWidth, minWidth: 'fit-content', transform: 'translateX(0%)' });
            }
        }
        setShowPopup(prev => !prev);
    }


    const handleClickOutside = (event: { target: any; }) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowPopup(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="info-container" ref={menuRef}>
            <div
                className="cursor-pointer infoIcon"
                onClick={() => handlePopup()}
            >
                <AiOutlineInfoCircle className={`${classname}`} />
            </div>
            {showPopup && (
                <div className="popup" ref={popupRef}  style={{ ...popupStyle, transform: translateX }}>
                    <p className='margin-0 text-start info-text-wrap' dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            )}
        </div>
    )
}