import { atom } from 'recoil';

export interface legendProps {
    show: boolean;
    legend_label: string;
    legend_end: string;
    legend_end_color: string;
    legend_mid: string
    legend_start: string
    legend_start_color: string;
}

export interface poiColorProps {
    geoId: number,
    poiColor: string,
    totalPoiCount: number,
    totalPoiCountDisplayValue: string;
}

export interface rootProps {
    geoId: number | string;
    geoName: string;
    region: string;
    show: boolean;
}

export interface geoJSONProps {
    type: string;
    features: any;
    legendProperties: legendProps;
    rootProperties: rootProps;
}

export interface mapColorProps {
    poiColourProperties: poiColorProps[];
    poiLegendProperties: legendProps
    rootProperties: rootProps;
}

const geoJsonState = atom({
    key: 'geojson',
    default: {} as geoJSONProps
});

const poiMapColorState = atom({
    key: 'poiMapColor',
    default: {} as mapColorProps
});

export { geoJsonState, poiMapColorState };