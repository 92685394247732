import { atom } from "recoil";

export interface POICatsState {
    key: number;
    name: string;
    sub_categories?: {
        key: number;
        name: string;
    }[]
}

export interface selPOICatState {
    category: number[];
    subcategory: number[];
}

export const POICategories = atom({
    key: 'POICategories',
    default: [] as POICatsState[],
});

export const selPOICategories = atom({
    key: 'selPOICategories',
    default: { category: [], subcategory: [] } as selPOICatState,
});

export const selectedCategoryState = atom({
    key: 'selectedCategory',
    default: { } as any,
});

export const cifTypeState = atom<'PoI' | 'CTV'>({
    key: 'cifTypeState',
    default: 'PoI',
});

export const brandList = atom({
    key: 'brandList',
    default: [] as any[],
});

export const selectedBrands = atom({
    key: 'selectedBrands',
    default: [] as any[],
});

