import styles from './Legend.module.css';
import { Body, BodyColor, BodyType, Heading, TypographyColor, TypographyType } from '..';
import { useRecoilValue } from 'recoil';
import { cifTypeState, userCurrencyState } from '../../states';
import { useMapHelpers } from '../../helpers';

interface LegendProps {
    data: {
        show: boolean,
        legendLabel: string,
        legendStart: number,
        legendEnd: number,
        legendStartColor: string,
        legendEndColor: string
    }
    classname?: string;
}

export const Legend: React.FC<LegendProps> = ({ data, classname }) => {
    const cifType = useRecoilValue(cifTypeState);
    const selCurrency = useRecoilValue(userCurrencyState);
    const { getCurrencyWithSymbol } = useMapHelpers();

    return (
        <>
            {data?.show &&
                <div className={`bg-white padding-3 rounded w-100 ${styles.legend_font_style} ${classname}`}>
                    <Heading
                        title={data.legendLabel}
                        type={TypographyType.h6}
                        colour={TypographyColor.primary}
                        classname='text-center padding-bottom-1'
                    />
                    <div className={`w-100 ${styles.legend}`} style={{ background: `linear-gradient(90deg, ${data.legendStartColor} 0%, ${data.legendEndColor} 100%)` }} />
                    <div className='d-flex align-items-center justify-content-between'>
                        <Body
                            type={BodyType.p3}
                            color={BodyColor.muted}
                            classname="p-0"
                        >
                            {cifType === 'CTV' ? getCurrencyWithSymbol(data.legendStart, selCurrency) : data.legendStart}
                        </Body>
                        <Body
                            type={BodyType.p3}
                            color={BodyColor.muted}
                            classname="p-0"
                        >
                            {cifType === 'CTV' ? getCurrencyWithSymbol(data.legendEnd, selCurrency) : data.legendEnd}
                        </Body>
                    </div>
                </div>}
        </>
    );
};