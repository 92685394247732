import { atom } from "recoil";

export const visiblePanelState = atom({
    key: 'visiblePanel',
    default: window.location.pathname || '/',
});

export const helpState = atom({
    key: 'showHelp',
    default: 0,
});

export const overlayState = atom({
    key: 'overlay',
    default: false,
});

export const popoverState = atom<string | null>({
    key: 'popover',
    default: null,
});

export const spinnerState = atom<number>({
    key: 'spinner',
    default: 0,
})

export const spinnerLiteState = atom<number>({
    key: 'spinnerLite',
    default: 0,
})

export const spinnerOpacityState = atom<boolean>({
    key: 'spinnerOpacityState',
    default: false,
});