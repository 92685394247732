import React from 'react';
import styles from "./Input.module.css";

interface InputProps {
    type?: string;
    placeholder?: string;
    value?: any;
    checked?: any;
    name?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    disabled?: boolean;
    maxlength?: number;
    pattern?: any;
    classname?: string;
    onBlur?: any;
    ref?: any;
    id?: string;
    min?: number;
    max?: number;
}

export const Input = ({ type, placeholder, value, checked, name, onChange, disabled, maxlength, pattern, classname, id, min, max }: InputProps) => {
    return (
        <input
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            disabled={disabled}
            maxLength={maxlength}
            pattern={pattern}
            className={`${styles.input} ${classname}`}
            id={id}
            checked={checked}
            min={min}
            max={max}
        >

        </input>
    )
}