// External libraries
import { useRecoilValue, useSetRecoilState } from 'recoil';

// Components
import { cifState, POICategories, spinnerState, userCurrencyState } from "../states";

// Utilities
import { useFetchWrapper } from '../helpers';
import { APIS, CATEGORY_ORDER } from '../constants';


const useCIFService = () => {
    const fetchWrapper = useFetchWrapper();
    const setDashboardData = useSetRecoilState(cifState);
    const setPoiCategories = useSetRecoilState(POICategories);
    const setSpinner = useSetRecoilState(spinnerState);
    const selCurrency = useRecoilValue(userCurrencyState);

    const getInOutFlowData = (geoCode: number) => {
        setSpinner(prev => prev + 1);
        const currency = localStorage.getItem('currency');
        return fetchWrapper.get(`${APIS.CIF.GET_INOUTFLOW_DATA}?geo-code=${geoCode}&currency=${currency}`).then(data => {
            setDashboardData(prevCIFData => ({
                ...prevCIFData,
                inOutFlowData: data
            }));
            setSpinner(prev => prev - 1);
        }).catch(error => {
            setSpinner(prev => prev - 1);
        });
    }

    const getEHGrowthData = (geoCode: number) => {
        setSpinner(prev => prev + 1);
        const currency = localStorage.getItem('currency');
        return fetchWrapper.get(`${APIS.CIF.GET_EH_GROWTH_DATA}?geo-code=${geoCode}&currency=${currency}`).then(data => {
            setDashboardData(prevCIFData => ({
                ...prevCIFData,
                ehGrowthGraphData: data
            }));
            setSpinner(prev => prev - 1);
        }).catch(error => {
            setSpinner(prev => prev - 1);
        });
    }

    const getMetricBreakdownData = (geoCode: number) => {
        setSpinner(prev => prev + 1);
        const currency = localStorage.getItem('currency');
        return fetchWrapper.get(`${APIS.CIF.GET_METRIC_BREAKDOWN}?geo-code=${geoCode}&currency=${currency}`).then(data => {
            setDashboardData(prevCIFData => ({
                ...prevCIFData,
                metricBreakdownInfo: data
            }));
            setSpinner(prev => prev - 1);
        }).catch(error => {
            setSpinner(prev => prev - 1);
        });
    }

    const getGeoSpecificData = (geoCode: number) => {
        setSpinner(prev => prev + 1);
        const currency = localStorage.getItem('currency');
        return fetchWrapper.get(`${APIS.CIF.GET_GEO_SPECIFIC_DATA}?geo-code=${geoCode}&currency=${currency}`).then(data => {
            setDashboardData(prevCIFData => ({
                ...prevCIFData,
                geoInfo: data
            }));
            setSpinner(prev => prev - 1);
        }).catch(error => {
            setSpinner(prev => prev - 1);
        });
    }

    const getCoreSolutionsGraphData = (geoCode: number) => {
        setSpinner(prev => prev + 1);
        const currency = localStorage.getItem('currency');
        return fetchWrapper.get(`${APIS.CIF.GET_CORE_SOLUTIONS_DATA}?geo-code=${geoCode}&currency=${currency}`).then(data => {
            setDashboardData(prevCIFData => ({
                ...prevCIFData,
                coreSolutionsData: data
            }));
            setSpinner(prev => prev - 1);
        }).catch(error => {
            setSpinner(prev => prev - 1);
        });
    }

    const sendEmail = (payload: any) => {
        return fetchWrapper.post(APIS.CIF.SEND_EMAIL, payload);
    }

    const getPOICategoryList = async () => {
        setSpinner(prev => prev + 1);
        try {
            const response = await fetchWrapper.get(`${APIS.CIF.GET_POI_CATEGORIES}`);
            const catData = response.sort((a: any, b: any) => CATEGORY_ORDER.indexOf(a.name) - CATEGORY_ORDER.indexOf(b.name));
            setPoiCategories(catData);
            setSpinner(prev => prev - 1);
        } catch (error) {
            setSpinner(prev => prev - 1);
        }
    }

    const getBasicCif = (geoCode: number) => {
        return fetchWrapper.get(`${APIS.CIF.GET_BASIC_CIF}?geo-code=${geoCode}&currency=${selCurrency}`);
    }

    const getCifCtvData = (geoCode: number) => {
        return fetchWrapper.get(`${APIS.CIF.GET_CIF_CTV}?geo-code=${geoCode}`);
    }

    const getCifPoiData = (payload: any) => {
        return fetchWrapper.post(APIS.CIF.GET_POI_CAT_DETAILS, payload);
    }

    const getPoiByYear = (payload: any) => {
        return fetchWrapper.post(APIS.CIF.GET_POI_BY_YEAR, payload);
    }

    return {
        getInOutFlowData,
        getEHGrowthData,
        getMetricBreakdownData,
        getGeoSpecificData,
        getCoreSolutionsGraphData,
        sendEmail,
        getPOICategoryList,
        getBasicCif,
        getCifCtvData,
        getCifPoiData,
        getPoiByYear,
    }
}

export { useCIFService };