// External libraries
import { useRecoilState } from 'recoil';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

// Components
import { authState } from '../states';

// Utilities
import { APIS, RouteConstants } from '../constants';
import { useDb } from '../indexDB/db';
import { useIDBService } from '../services';

// Variables to manage token refresh state and subscribers
let isRefreshing = false;
let refreshSubscribers: any[] = [];

// Function to add a callback to the subscribers array
const subscribeTokenRefresh = (cb: any) => {
    refreshSubscribers.push(cb);
}

// Function to notify all subscribers with the new token
const onRrefreshed = (token: string) => {
    refreshSubscribers?.map(cb => cb(token));
}

function useFetchWrapper() {
    const [auth, setAuth] = useRecoilState(authState);
    const navigate = useNavigate();
    const db = useDb();
    const IDBService = useIDBService();

    // Create an Axios instance with common headers
    const axiosInstance: AxiosInstance = axios.create({
        baseURL: '',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    // Interceptor for adding authorization headers
    axiosInstance.interceptors.request.use(
        async (config: any) => {
            // Skip adding Authorization header for specific endpoints
            if ([APIS.USERS.LOGIN, APIS.USERS.FORGOT_PASSWORD, APIS.USERS.SET_NEW_PASSWORD].includes(config.url)) return config;
            const user = localStorage.getItem('user') || null;
            if (user != null) {
                const token = JSON.parse(user)?.tokens?.access;
                var isTokenExpired = checkTokenExpiry(token);
                if (!isTokenExpired) {
                    // Add Authorization header if token is not expired
                    config.headers['Authorization'] = `Bearer ${token}`;
                } else {
                    // Handle token refresh
                    if (!isRefreshing) {
                        isRefreshing = true;
                        try {
                            const data = await getRefreshToken();
                            const newAccessToken = data.access;
                            const updatedAuth = {
                                ...auth,
                                tokens: {
                                    ...auth.tokens,
                                    access: newAccessToken
                                }
                            };
                            setAuth(updatedAuth);
                            localStorage.setItem('user', JSON.stringify(updatedAuth));
                            config.headers['Authorization'] = `Bearer ${newAccessToken}`;
                            onRrefreshed(newAccessToken);
                        } catch (error: any) {
                            ['user', 'email_id', 'user_id'].forEach((item: string) => localStorage.removeItem(item));
                            setAuth({});
                            navigate(RouteConstants.login);
                            throw error;

                        } finally {
                            isRefreshing = false;
                            refreshSubscribers = [];
                        }
                    } else {
                        // Wait for the token to be refreshed
                        return new Promise((resolve) => {
                            subscribeTokenRefresh((newAccessToken: string) => {
                                config.headers['Authorization'] = `Bearer ${newAccessToken}`;
                                resolve(config);
                            });
                        });
                    }
                }
                return config;
            }
            return config;
        },
        (error: any) => {
            return Promise.reject(error);
        }
    );

    return {
        get: (url: string, params?: any) => axiosInstance.get(getURL(url), { params }).then(handleResponse).catch(handleError),
        post: (url: string, data?: any) => axiosInstance.post(getURL(url), data).then(handleResponse).catch(handleError),
        put: (url: string, data?: any) => axiosInstance.put(getURL(url), data).then(handleResponse).catch(handleError),
        patch: (url: string, data?: any) => axiosInstance.patch(getURL(url), data).then(handleResponse).catch(handleError),
        delete: (url: string, data?: any) => axiosInstance.delete(getURL(url), data).then(handleResponse).catch(handleError),
    };

    function getURL(url: string) {
        let host = process.env.REACT_APP_API_HOST_DEV;
        let baseURL = process.env.REACT_APP_API_BASE_URL;
        if (window.location.host === host) {
            url = baseURL + url;
        }
        return url;
    }

    async function handleResponse(response: AxiosResponse) {
        if (![APIS.USERS.LOGIN, APIS.USERS.GET_LOGGED_USER, APIS.USERS.GET_NEW_ACCESS_TOKEN].some(path => response.request.responseURL.includes(path))) {
            await db.storeResponseInDB(response); // Store error responses in IndexedDB
        }

        if (![200, 201, 204, 409].includes(response.status)) {
            if ([401, 403].includes(response.status) && auth?.token) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                ['user', 'email_id', 'user_id'].forEach((item: string) => localStorage.removeItem(item));
                setAuth(null);
            }
            const error = (response?.data?.detail) || response?.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }

    function handleError(error: any) {
        if (error.response) {
            if (![APIS.USERS.LOGIN, APIS.USERS.GET_LOGGED_USER, APIS.USERS.GET_NEW_ACCESS_TOKEN].some(path => error.response.request.responseURL.includes(path))) {
                db.storeResponseInDB(error.response); // Store error responses in IndexedDB
            }
        }
        return Promise.reject(error);
    }

    // async function getRefreshToken() {
    //     const refresh = auth?.tokens?.refresh;
    //     const access = auth?.tokens?.access;
    //     const isExpired = checkTokenExpiry(refresh);
    //     if (isExpired) {
    //         localStorage.removeItem('user');
    //         localStorage.removeItem('email_id');
    //         setAuth(null);
    //         const error = { response: { data: { detail: 'Invalid refresh token' } } }
    //         throw error;
    //     } else {
    //         const headers = {
    //             'Authorization': `Bearer ${access}`,
    //             'Content-Type': 'application/json'
    //         };
    //         const response = await axios.post(getURL(APIS.USERS.GET_NEW_ACCESS_TOKEN), { refresh }, { headers });
    //         return handleResponse(response);
    //     }
    // }

    async function getRefreshToken() {
        const refresh = auth?.tokens?.refresh;
        const access = auth?.tokens?.access;
        const isExpired = checkTokenExpiry(refresh);
        // const completeUrl = `${window.location.host.includes('localhost')} ? '/dotsadmin' : '' + ${APIS.USERS.GET_NEW_ACCESS_TOKEN}`
        const completeUrl = APIS.USERS.GET_NEW_ACCESS_TOKEN;

        if (isExpired) {
            ['user', 'email_id', 'user_id'].forEach((item: string) => localStorage.removeItem(item));
            setAuth(null);
            const error = { response: { data: { detail: 'Invalid refresh token' } } }
            throw error;
        }

        const headers = {
            'Authorization': `Bearer ${access}`,
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.post(getURL(completeUrl), { refresh }, { headers });

            // After successful token refresh, retrieve, post the data and clear IndexedDB
            IDBService.postUserMetrics();
            return handleResponse(response);
        } catch (error) {
            ['user', 'email_id', 'user_id'].forEach((item: string) => localStorage.removeItem(item));
            setAuth(null);
            navigate(RouteConstants.login);
            throw error;
        }
    }

    function checkTokenExpiry(token: any) {
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        const expiryTime = tokenData.exp * 1000;
        const currentTime = Date.now();
        return currentTime >= expiryTime;
    }
}

export { useFetchWrapper };

