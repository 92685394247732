import './Spinner.css';
import { spinnerOpacityState, spinnerState } from '../../states';
import { useRecoilState } from 'recoil';
// import spinnerGif from '../../utils/images/spinnerGIF.gif';
import spinnerGif2 from '../../utils/images/spinnerGIF2.gif'


export const Spinner = ({ spinnerFlag }: { spinnerFlag?: boolean }) => {
    const [spinner] = useRecoilState(spinnerState);
    const [isOpacity, setIsOpacity] = useRecoilState(spinnerOpacityState);
    return (
        <>
            {(spinnerFlag || spinner > 0) &&
                <div className={`spinner-wrapper1 ${isOpacity ? 'spinner-opacity' : ''}`}>
                    <div className="spinner-overlay1 d-flex justify-content-center align-items-center">
                        <div className="w-100">
                            <img src={spinnerGif2} alt="spinner" className="spinner-size" />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
